
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'

@Component({})
  export default class PeriodInspections extends BaseChart {
  brief = ''

  // Methods
  mounted () {
    this.getData()
    this.display = true
  }

  @Prop() inspections!: Record<string, any>
  @Prop() inspectionsSettings!: Record<string, any>
  @Prop({ default: undefined, type: Number }) executives
  @Prop({ default: undefined, type: Number }) idExecutive

  getData () {
    let inspections = this.inspections?.records
    const inspectionsSettings = this.inspectionsSettings?.settingsProcess?.[0]
    if (!inspections || !inspectionsSettings) return

    if (this.idExecutive) {
      inspections = inspections.filter(inspection => inspection.executiveId === this.idExecutive)
    }

    const purchasesAmount = inspections.length

    const monthlyTarget = Math.round((inspectionsSettings?.config.projectedUtility.quantity / (this.executives || 1)) * (this.executives ? 1.2 : 1))

    const percentage = (purchasesAmount / monthlyTarget) * 100

    this.brief = `${purchasesAmount} de ${monthlyTarget}`
    this.series = [Math.round(percentage)]

    this.chartOptions = {
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
              fontSize: '15px',
              fontWeight: 500,
              color: '#737373',
              offsetY: 32,
              formatter: () => 'Inspecciones agendadas',
            },
            value: {
              show: true,
              fontSize: '35px',
              fontWeight: 800,
              color: '#737373',
              offsetY: -1,
            },
          },
        },
      },
      fill: {
        type: 'solid',
        colors: [this.getBarColor(percentage)],
      },
    }
  }

  // Getters

  get watchData () {
    return {
      inspections: this.inspections,
      inspectionsSettings: this.inspectionsSettings,
      executives: this.executives,
    }
  }

  // Watchers
  @Watch('watchData', { immediate: true, deep: true })
  onDataChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
