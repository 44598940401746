
  import Component from 'vue-class-component'
  import { Prop } from 'vue-property-decorator'
  import { DetailChart } from '../detailChart'
  import dayjs from 'dayjs'

@Component({})
  export default class CarDetail extends DetailChart {
  @Prop() car

  get date () {
    if (this.carDate) {
      return dayjs(this.carDate).format('DD/MM/YYYY')
    }
    return ''
  }

  get carDate () {
    if (this.car.soldDate) return this.car.soldDate
    if (this.car.stockCreated) return this.car.stockCreated
    if (this.car.soldDate) return this.car.soldDate
    if (this.car.signedDate) return this.car.signedDate
  }

  get carPlate () {
    if (this.car.registrationPlate) {
      return this.car.registrationPlate
    }
    if (this.car.auto?.registrationPlate) return this.car.auto?.registrationPlate
    if (this.car.financing?.saleOrder?.deal?.auto?.registrationPlate) {
      return this.car.financing?.saleOrder?.deal?.auto?.registrationPlate
    }
    if (this.car.stockDetail.auto.registrationPlate) {
      return this.car.stockDetail.auto.registrationPlate
    }
  }

  get executiveFullName () {
    if (this.car.executiveName) {
      return this.car.executiveName
    }
    let executive = this.car.deals?.[0]?.lead?.executive
    if (!executive) {
      executive = this.car.executive
    }
    if (!executive) {
      executive = this.car.financing?.saleOrder?.deal?.lead?.executive
    }

    if (executive) {
      const name = executive.person?.firstName
      const lastName = executive.person?.surname
      return `${name} ${lastName}`
    }
    return 'Sin ejecutivo'
  }

  get carModel () {
    if (this.car.auto) return this.car.auto
    if (this.car.stockDetail?.auto) {
      return this.car.stockDetail?.auto
    }
    if (this.car.financing?.saleOrder?.deal?.auto) {
      return this.car.financing?.saleOrder?.deal?.auto
    }
  }

  get carFullName () {
    if (this.car.autoDescription && this.car.year) {
      return `${this.car.autoDescription} ${this.car.year}`
    }
    const brand = this.carModel.versionYear?.version?.model?.brand?.name
    const model = this.carModel.versionYear?.version?.model?.name
    return `${brand} ${model}`
  }
  }
