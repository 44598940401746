
  import Component from 'vue-class-component'
  import { Prop } from 'vue-property-decorator'
  import { BaseChart } from '../baseChart'

@Component({})
  export default class ExecutiveDetail extends BaseChart {
  @Prop() executive
  @Prop() target
  @Prop() staffExecutives
  @Prop({ default: true, type: Boolean }) multiplyTarget

  get percentage () {
    return (this.executive.amount / (this.individualTarget || 1)) * 100
  }

  get individualTarget () {
    return (this.executive.executive?.target !== undefined ? this.executive.executive?.target : Math.round(this.target * (this.multiplyTarget ? 1.2 : 1)))
  }

  get isStaff () {
    if (!this.staffExecutives) return false
    const executiveName = this.executive?.name?.toLowerCase()
    const staff = this.staffExecutives.map(executive => `${executive.first_name} ${executive.surname}`.toLowerCase())

    const staffIds = this.staffExecutives.map(staff => staff.id_executive)
    if (staffIds.includes(this.executive?.executive?.id)) {
      return true
    }
    return staff.includes(executiveName)
  }
  }
