
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import _ from 'lodash'

@Component({})
  export default class PeriodApprovedCredits extends BaseChart {
  brief = ''

  // Methods
  mounted () {
    this.getData()
    this.display = true
  }

  @Prop() credits!: Record<string, any>
  @Prop() creditsSettings!: Record<string, any>
  @Prop({ default: undefined, type: Number }) executives
  @Prop({ default: undefined, type: Number }) idExecutive

  getData () {
    let credits = this.credits?.records?.nodes
    const creditsSettings = this.creditsSettings?.settings_process?.[0]
    if (!credits || !creditsSettings) return

    credits = _.cloneDeep(credits)

    if (this.idExecutive) {
      credits = credits.filter(credit => credit.executive.id === this.idExecutive)
    }
    const purchasesAmount = credits.filter(credit => credit.approvalDate).length

    const monthlyTarget = Math.round(((creditsSettings?.config.projectedUtility.approved || 50) / (this.executives || 1)) * (this.executives ? 1.2 : 1))

    const percentage = (purchasesAmount / monthlyTarget) * 100

    this.brief = `${purchasesAmount} de ${monthlyTarget}`
    this.series = [Math.round(percentage)]

    this.chartOptions = {
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
              fontSize: '15px',
              fontWeight: 500,
              color: '#737373',
              offsetY: 32,
              formatter: () => 'Créditos aprobados',
            },
            value: {
              show: true,
              fontSize: '35px',
              fontWeight: 800,
              color: '#737373',
              offsetY: -1,
            },
          },
        },
      },
      fill: {
        type: 'solid',
        colors: [this.getBarColor(percentage)],
      },
    }
  }

  // Getters

  get watchData () {
    return {
      credits: this.credits,
      creditsSettings: this.creditsSettings,
      executives: this.executives,
    }
  }

  // Watchers
  @Watch('watchData', { immediate: true, deep: true })
  onDataChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
