
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { DetailByExecutive } from './detailByExecutive'
  import ExecutiveDetail from './Detail/ExecutiveDetail.vue'

@Component({
  components: {
    ExecutiveDetail,
  },
})
  export default class CreditsByExecutive extends DetailByExecutive {
  @Prop() credits: Record<string, any>
  @Prop() creditsTarget: Record<string, any>

  amount = 0
  target = 10
  cars = []
  executives = []

  // Methods
  mounted () {
    this.getData()
  }

  async getData () {
    if (!this.credits?.records) return

    this.amount = this.credits?.records?.aggregate?.count || 0
    const cars = this.credits?.records?.nodes
    if (cars) {
      this.cars = [...cars].sort((prev, next) => {
        return -dayjs(prev.signedDate).diff(dayjs(next.signedDate))
      })
    }
    const creditsTarget = this.creditsTarget?.settings_process?.[0]?.config?.projectedUtility?.quantity
    if (creditsTarget) {
      this.target = creditsTarget
    }

    let executives = []
    if (cars) {
      cars.map(car => {
        let executive = car.executive?.person
        if (!executive) {
          executive = {
            firstName: 'Sin',
            surname: 'ejecutivo',
          }
        }
        const newExecutive = `${executive.firstName} ${executive.surname}`
        if (!executives.includes(newExecutive)) {
          executives.push(newExecutive)
        }
      })
    }

    const lowercasedExecutives = executives.map(executive => executive.toLowerCase())
    const lowercasedStaff = this.staffExecutives?.map(executive =>
      `${executive.first_name} ${executive.surname}`.toLowerCase()
    )
    if (this.staffExecutives) {
      this.staffExecutives.forEach(executive => {
        const executiveName = `${executive.first_name} ${executive.surname}`.toLowerCase()
        if (!lowercasedExecutives.includes(executiveName)) {
          executives.push(`${executive.first_name} ${executive.surname}`)
        }
      })
    }

    this.executives = []
    executives = executives.sort()
    if (lowercasedStaff) {
      executives = executives.sort((prev, next) => {
        if (lowercasedStaff.includes(prev.toLowerCase())) {
          if (lowercasedStaff.includes(next.toLowerCase())) {
            return 1
          } else {
            return -1
          }
        } else {
          return 1
        }
      })
    }

    executives.forEach(executive => {
      const filtered = cars.filter(car => {
        let executiveName = ''
        const personExecutive = car.executive?.person
        if (personExecutive === undefined) {
          executiveName = 'Sin ejecutivo'
        } else {
          executiveName = `${personExecutive.firstName} ${personExecutive.surname}`
        }
        return executiveName === executive
      })

      this.executives.push({
        name: executive,
        amount: filtered.length,
      })
    })
  }

  @Watch('credits', { immediate: true, deep: true })
  @Watch('creditsTarget', { immediate: true, deep: true })
  @Watch('staffExecutives', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
