
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'

@Component({})
  export default class PeriodPurchases extends BaseChart {
  brief = ''

  // Methods
  mounted () {
    this.getData()
    this.display = true
  }

  @Prop() purchases!: Record<string, any>
  @Prop() target!: Record<string, any>
  @Prop({ default: undefined, type: Number }) executives

  getData () {
    if (!this.purchases || !this.target) return
    const purchases = this.purchases
    const targetPurchases = this.target

    if (!purchases) {
      this.series = [0]
      this.chartOptions.fill.colors = this.getBarColor(0)
      return
    }

    let purchasesAmount = purchases?.records?.aggregate?.count
    if (!purchasesAmount) purchasesAmount = 0

    let monthlyTarget
    if (!targetPurchases.settingsProcess?.[0]?.config?.projectedUtility?.quantity) {
      monthlyTarget = 10
    } else {
      monthlyTarget = Math.round((targetPurchases?.settingsProcess?.[0]?.config.projectedUtility.quantity / (this.executives || 1)) * (this.executives ? 1.2 : 1))
    }

    // const target = (monthlyTarget / 30) * (dayjs(endDate).diff(startDate, 'day') + 1)

    const percentage = (purchasesAmount / monthlyTarget) * 100

    this.brief = `${purchasesAmount} de ${monthlyTarget}`
    this.series = [Math.round(percentage)]

    this.chartOptions = {
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: '20px',
              fontWeight: 500,
              color: '#737373',
              offsetY: 32,
            },
            value: {
              show: true,
              fontSize: '35px',
              fontWeight: 800,
              color: '#737373',
              offsetY: -17,
            },
          },
        },
      },
      labels: ['Compras'],
      fill: {
        type: 'solid',
        colors: [this.getBarColor(percentage)],
      },
    }
  }

  // Getters

  // Watchers
  @Watch('purchases', { immediate: true, deep: true })
  @Watch('target', { immediate: true, deep: true })
  @Watch('executives', { immediate: true, deep: true })
  onDataChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
