
  import { fixAmountFormatted, fixPrice } from '@/utils/general'
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Legend } from './Legend'

@Component
  export default class ChartVerticalLegend extends Vue {
  @Prop() legends: Legend[]
  @Prop({ default: false }) refChart
  @Prop({ default: false, type: Boolean }) isCurrency
  @Prop({ default: 'total', type: String }) totalSufix
  @Prop({ default: false, type: Boolean }) filterZeroLegends
  @Prop({ default: undefined, type: String }) header

  toggleSerie (serie) {
    if (!this.refChart) return
    const notHide = [serie, serie + ' (mes anterior)']
    this.legends.forEach(legend => {
      if (notHide.includes(legend.name)) {
        this.refChart.showSeries(legend.name)
        this.refChart.showSeries(legend.name + ' (mes anterior)')
      } else {
        this.refChart.hideSeries(legend.name)
        this.refChart.hideSeries(legend.name + ' (mes anterior)')
      }
    })
  }

  formatValue (value: number): string {
    if (this.isCurrency) {
      return fixPrice(value)
    } else return fixAmountFormatted(value)
  }

  get sortedLegends () {
    const { legends } = this
    return legends
      .filter(legend => this.filterZeroLegends ? legend.value !== 0 : true)
      .sort((prev, next) => next.value - prev.value)
  }

  get total () {
    let sum = 0
    this.legends.forEach(legend => sum += legend.value)
    return sum
  }
  }
