
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { fixPrice } from '@/utils/general'

@Component({})
  export default class UtilityPeriodTotal extends BaseChart {
  brief = ''

  // Methods
  mounted () {
    this.getData()
    this.display = true
  }

  @Prop() sales: Record<string, any>
  @Prop() purchases: Record<string, any>
  @Prop() credits: Record<string, any>
  @Prop() salesTarget: Record<string, any>
  @Prop() creditsTarget: Record<string, any>

  getData () {
    if (!this.sales || !this.purchases || !this.credits || !this.salesTarget || !this.creditsTarget) return
    const sales = this.sales
    const purchases = this.purchases
    const credits = this.credits
    const targetSales = this.salesTarget
    const targetCredits = this.creditsTarget

    let salesAmount = sales?.sales_sale_order_item_aggregate?.aggregate?.sum.amount
    if (!salesAmount) salesAmount = 0

    let purchasesAmount = purchases?.sales_stock_view_aggregate?.aggregate?.sum.total_cost
    if (!purchasesAmount) purchasesAmount = 0

    let creditAmount = credits?.loans_offer_aggregate?.aggregate?.sum?.fee
    if (!creditAmount) creditAmount = 0

    const utilityAmount = salesAmount - purchasesAmount + creditAmount

    let salesTarget = targetSales?.settings_process?.[0]?.config?.projectedUtility?.amount
    if (!salesTarget) salesTarget = 50000000

    let creditTarget = targetCredits?.settings_process?.[0]?.config?.projectedUtility?.amount
    if (!creditTarget) creditTarget = 10000000

    const totalTarget = salesTarget + creditTarget

    // const target = (monthlyTarget / 30) * (dayjs(endDate).diff(startDate, 'day') + 1)

    const percentage = (utilityAmount / totalTarget) * 100

    this.brief = `${utilityAmount ? fixPrice(utilityAmount) : fixPrice(0)}`
    this.series = [Math.round(percentage)]
    this.chartOptions = {
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: '20px',
              fontWeight: 500,
              color: '#737373',
              offsetY: 32,
            },
            value: {
              show: true,
              fontSize: '40px',
              fontWeight: 800,
              color: '#737373',
              offsetY: -17,
            },
          },
        },
      },
      labels: ['Total'],
      fill: {
        type: 'solid',
        colors: [this.getBarColor(percentage)],
      },
    }
  }

  // Getters

  // Watchers
  @Watch('sales', { immediate: true, deep: true })
  @Watch('purchases', { immediate: true, deep: true })
  @Watch('credits', { immediate: true, deep: true })
  @Watch('salesTarget', { immediate: true, deep: true })
  @Watch('creditsTarget', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
