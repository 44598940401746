
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import { Role } from '@/store/user/models'
  import StaffDashboard from '@/components/dashboards/Roles/staff/staff.vue'
  import { mapGetters } from 'vuex'

@Component({
  components: {
    StaffDashboard,
  },
  computed: {
    ...mapGetters('user', ['roles']),
    ...mapGetters('app', ['isMobile']),
  },
})
  export default class Home extends Vue {
  isMobile!: boolean
  roles!: Array<Role>
  displayStaff = false
  img = require('@/assets/genio/GENIO_welcome.svg')

  @Watch('roles', { immediate: true, deep: true })
  async onRolesChange (val) {
    this.displayStaff = val.some(role => role.slug === 'staff')
  }

  get width () {
    const { $vuetify } = this

    return $vuetify.breakpoint.width - 25
  }

  get height () {
    const { isMobile } = this
    return isMobile ? 400 : 800
  }
  }
