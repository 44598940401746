export function fixColor (color: string): string {
  const colorRegex = /#([a-fA-F0-9]{3}|[a-fA-F0-9]{6}|[a-fA-F0-9]{8})/
  if (!color || !colorRegex.test(color)) return '#737373'
  if (color === 'black') return '#000'
  if (color === 'white') return '#fff'
  return color.trim()
}

export function lowOpacityColor (color: string) {
  return `${fixColor(color)}4C`
}
