import dayjs from 'dayjs'

export const filters = (dates, idExecutive: number) => {
  const start = dayjs(dates._gte)
  const end = dayjs(dates._lte)

  if (start.isAfter(end)) {
    console.error('Invalid date interval: _lte should not be a prior date to _gte', `dates: ${JSON.stringify(dates)}`)
  }

  return {
    sales: {
      start_date: dates._gte,
      end_date: dates._lte,
    },
    signedLoans: {
      model: {
        type: {
          name: {
            _eq: 'financing',
          },
        },
      },
      aggregate: {
        _and:
          [
            {
              signed_date: dates,
            },
            {
              closing_reason:
                {
                  type: {
                    name: { _eq: 'signed' },
                  },
                },
            },
          ],
      },
    },
    closingReason: {
      model: {
        status: {
          process: {
            table_name: {
              _eq: 'lead',
            },
          },
        },
      },
      aggregate: {
        created_at: dates,
        executive: {
          id: {
            _eq: idExecutive,
          },
        },
      },
    },
    loanApplications: {
      filter: {
        created_at: dates,
        sale_order: {
          deal: {
            lead: {
              executive: {
                id: { _eq: idExecutive },
              },
            },
          },
        },
      },
    },
    leadsTask: {
      created_at: dates,
      executive: {
        id: {
          _eq: idExecutive,
        },
      },
    },
    inspectionsStatus: {
      start_date: dates._gte,
      end_date: dates._lte,
    },
    credit: {
      aggregate: {
        _and: [
          { signed_date: dates },
          { closing_reason: { type: { name: { _eq: 'signed' } } } },
          { closing_reason_deal: { _eq: 'successful_sale' } },
          { executive: { id: { _eq: idExecutive } } },
        ],
      },
    },
    soldDate: {
      aggregate: {
        sold_date: dates,
        deals: {
          lead: {
            executive: { id: { _eq: idExecutive } },
          },
        },
      },
    },
    stockCreatedDate: {
      aggregate: {
        _and: {
          stock_created: dates,
          executive: { id: { _eq: idExecutive } },
        },
      },
    },
  }
}
