
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'

@Component({})
  export default class SignedLoans extends BaseChart {
  @Prop() loans: Record<string, any>
  loansAttribute = []
  colors = {}

  // Methods
  getData () {
    this.series = []
    this.chartOptions = {}
    if (this.loans?.records) {
      const loans = this.loans.records

      let signedLoans = loans.map(x => {
        return {
          company: x.person.alias,
          count: x.evaluations.aggregate.count,
        }
      })

      // Filter empty companies
      signedLoans = signedLoans.filter(loan => loan.count !== 0)
      signedLoans = signedLoans.sort((prev, next) => {
        return next.count - prev.count
      })
      this.loansAttribute = signedLoans

      const financeColors = [
        {
          company: 'Falabella',
          color: '#13CE66',
        },
        {
          company: 'Autofin',
          color: '#6185DB',
        },
        {
          company: 'Global',
          color: '#7D67BC',
        },
        {
          company: 'Eurocapital',
          color: '#FAD901',
        },
        {
          company: 'Santander',
          color: '#F03738',
        },
      ]
      financeColors.forEach(item => this.colors[item.company.toLowerCase()] = item.color)

      if (signedLoans.length > 0) {
        const colors = []
        signedLoans.map(x => x.company)
          .forEach(company => {
            const color = financeColors.filter(x => {
              return x.company.toLowerCase() === company.toLowerCase()
            })

            if (color[0]) {
              colors.push(color[0].color)
            } else colors.push(undefined)
          })

        const labels = signedLoans.map(x => x.company)
        const series = signedLoans.map(x => x.count)

        if (
          !labels.some(x => x === null || x === undefined) &&
          !series.some(x => x === null || x === undefined)
        ) {
          this.series = series
          this.chartOptions = {
            labels,
            colors,
            legend: {
              show: false,
              position: 'bottom',
              horizontalAlign: 'center',
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  minAngleToShowLabel: 1,
                },
              },
            },
          }
        }
      }
    }
  }

  // Getters
  get applicationsNumber () {
    let sum = 0
    this.series.forEach(el => {
      sum += el
    })
    return sum
  }

  // Watchers
  @Watch('loans', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
