
  import { lowOpacityColor } from '@/utils/dashboards'
  import { fixAmountFormatted, fixPrice } from '@/utils/general'
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Legend } from './Legend'

@Component
  export default class ChartHorizontalLegend extends Vue {
  @Prop() legends: Legend[]
  @Prop({ default: false, type: Boolean }) isCurrency
  @Prop({ default: 'total', type: String }) totalSufix
  @Prop({ default: '', type: String }) labelSufix
  @Prop({ default: true, type: Boolean }) colorLabels
  @Prop({ default: true, type: Boolean }) showTotals
  @Prop({ default: true, type: Boolean }) showTotal
  @Prop({ default: false, type: Boolean }) filterZeroLegends
  @Prop({ default: true, type: Boolean }) sort

  lowOpacityColor = lowOpacityColor

  formatValue (value: number): string {
    if (this.isCurrency) {
      return fixPrice(value)
    } else return fixAmountFormatted(value)
  }

  get total () {
    let sum = 0
    this.legends.forEach(legend => sum += legend.value)
    return sum
  }

  get sortedLegends () {
    const { legends } = this
    if (this.sort) {
      return legends
        .filter(legend => this.filterZeroLegends ? legend.value !== 0 : true)
        .sort((prev, next) => next.value - prev.value)
    }
    return legends
      .filter(legend => this.filterZeroLegends ? legend.value !== 0 : true)
  }
  }
