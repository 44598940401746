
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'

@Component({})
  export default class PeriodReserves extends BaseChart {
  brief = ''

  // Methods
  mounted () {
    this.getData()
    this.display = true
  }

  @Prop() reserves!: Record<string, any>
  @Prop() reservesSettings!: Record<string, any>
  @Prop({ default: undefined, type: Number }) executives
  @Prop({ default: undefined, type: Number }) idExecutive

  getData () {
    let reserves = this.reserves?.records
    const reservesSettings = this.reservesSettings?.settingsProcess?.[0] || { config: { projectedUtility: { quantity: 50 } } }
    if (!reserves || !reservesSettings) return

    if (this.idExecutive) {
      reserves = reserves.filter(reserve => reserve.saleOrder.deal.lead.executive.id === this.idExecutive)
    }

    const purchasesAmount = reserves.length
    const monthlyTarget = Math.round((reservesSettings?.config.projectedUtility.quantity / (this.executives || 1)) * (this.executives ? 1.2 : 1))

    const percentage = (purchasesAmount / monthlyTarget) * 100

    this.brief = `${purchasesAmount} de ${monthlyTarget}`
    this.series = [Math.round(percentage)]

    this.chartOptions = {
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: '20px',
              fontWeight: 500,
              color: '#737373',
              offsetY: 32,
            },
            value: {
              show: true,
              fontSize: '35px',
              fontWeight: 800,
              color: '#737373',
              offsetY: -17,
            },
          },
        },
      },
      labels: ['Reservas'],
      fill: {
        type: 'solid',
        colors: [this.getBarColor(percentage)],
      },
    }
  }

  // Getters

  get watchData () {
    return {
      reserves: this.reserves,
      reservesSettings: this.reservesSettings,
      executives: this.executives,
    }
  }

  // Watchers
  @Watch('watchData', { immediate: true, deep: true })
  onDataChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
