import dayjs from 'dayjs'

export const filters = dates => {
  const start = dayjs(dates._gte)
  const end = dayjs(dates._lte)

  if (start.isAfter(end)) {
    console.error('Invalid date interval: _lte should not be a prior date to _gte', `dates: ${JSON.stringify(dates)}`)
  }
  return {
    sales: {
      start_date: dates._gte,
      end_date: dates._lte,
    },
    leadsTask: {
      created_at: dates,
    },
    stockSpecs: {
      model: {
        status: {
          status: {
            name: {
              _neq: 'sold',
            },
          },
        },
      },
    },
    dailyStock: {
      model: {
        report_date: dates,
      },
    },
    leads: {
      model: {
        created_at: dates,
      },
    },
    createdDate: {
      aggregate: {
        created_at: dates,
      },
    },
    stockCreatedDate: {
      aggregate: {
        stock_created: dates,
      },
    },
    soldDate: {
      aggregate: {
        sold_date: dates,
      },
    },
    stock: {
      model: {
        process: {
          table_name: {
            _eq: 'stock',
          },
        },
      },
    },
    credit: {
      aggregate: {
        _and: [
          { signed_date: dates },
          { closing_reason: { type: { name: { _eq: 'signed' } } } },
          { closing_reason_deal: { _eq: 'successful_sale' } },
        ],
      },
    },
    allCredits: {
      aggregate: {
        approval_date: dates,
      },
    },
    salesAmount: {
      aggregate: {
        item_type: {
          name: {
            _neq: 'transfer',
          },
        },
        sale_order: {
          _and: [
            {
              id_document: {
                _is_null: false,
              },
            },
            {
              deal: {
                stock: {
                  _and: [
                    { sold_date: dates },
                    { status: { status: { name: { _eq: 'sold' } } } },
                  ],
                },
              },
            },
          ],
        },
      },
    },
    utilityCredits: {
      aggregate: {
        accepted: { _eq: true },
        evaluation: {
          _and: [
            { signed_date: dates },
            { closing_reason: { type: { name: { _eq: 'signed' } } } },
            {
              financing: {
                sale_order: {
                  deal: {
                    closing_reason: {
                      type: {
                        name: {
                          _eq: 'successful_sale',
                        },
                      },
                    },
                  },
                },
              },
            },
          ],
        },
      },
    },
    leadsByExecutive: {
      model: {
        person: {
          user: {
            roles: {
              rol: {
                slug: {
                  _eq: 'staff',
                },
              },
            },
          },
        },
      },
      aggregate: { created_at: dates },
    },
    closingReason: {
      model: {
        status: {
          process: {
            table_name: {
              _eq: 'lead',
            },
          },
        },
      },
      aggregate: { created_at: dates },
    },
    soldPurchases: {
      aggregate: {
        stock: {
          _and: [
            { sold_date: dates },
            {
              status: {
                status: { name: { _eq: 'sold' } },
              },
            },
          ],
        },
      },
    },
    soldSales: {
      aggregate: {
        item_type: {
          name: {
            _neq: 'transfer',
          },
        },
        sale_order: {
          _and: [
            {
              id_document: {
                _is_null: false,
              },
            },
            {
              deal: {
                stock: {
                  _and: [
                    { sold_date: dates },
                    { status: { status: { name: { _eq: 'sold' } } } },
                  ],
                },
              },
            },
          ],
        },
      },
    },
    signedLoans: {
      model: {
        type: {
          name: {
            _eq: 'financing',
          },
        },
      },
      aggregate: {
        _and:
          [
            {
              signed_date: dates,
            },
            {
              closing_reason:
                {
                  type: {
                    name: { _eq: 'signed' },
                  },
                },
            },
          ],
      },
    },
    openEvaluations: {
      model: {
        created_at: dates,
      },
    },
    loanApplications: {
      filter: {
        created_at: dates,
      },
    },
    inspectionsStatus: {
      start_date: dates._gte,
      end_date: dates._lte,
    },
  }
}
