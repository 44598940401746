<template>
  <v-container
    fluid
    class="fill-height d-flex flex-column justify-space-between pb-10"
  >
    <v-container
      class="list"
    >
      <v-row
        v-for="(inspection, index) in inspectionsAttribute"
        :key="index"
        class="my-2 pr-2"
      >
        <v-col>
          <v-row>
            <span>
              <p class="font-weight-black car-model">
                {{ inspection.autoDescription }} {{ inspection.year }}
              </p>
            </span>
            <v-spacer />
            <span class="font-weight-black d-flex align-center justify-center text-center plate">
              {{ inspection.registrationPlate }}
            </span>
          </v-row>
          <v-row v-if="!idExecutive">
            <p>Ejecutivo</p>
            <v-spacer />
            <p>{{ inspection.executive ? inspection.executive : 'SIN EJECUTIVO' }} {{ dayjs(inspection.scheduledDate).format('DD/MM/YYYY') }}</p>
          </v-row>
          <v-row>
            <p>Inspector</p>
            <v-spacer />
            <p>{{ inspection.inspector ? inspection.inspector : 'SIN INSPECTOR' }} {{ inspection.closinReasonDate !== null ? dayjs(inspection.closinReasonDate).format('DD/MM/YYYY') : 'SIN FECHA' }}</p>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="d-flex flex-column mb-n12">
      <v-row class="justify-self-end">
        <v-col cols="12">
          <span class="d-flex font-weight-black justify-space-around">
            {{ inspectionsAttribute.length }} de {{ allInspections.length }} agendadas
          </span>
        </v-col>
      </v-row>
      <v-row class="justify-self-end">
        <v-col cols="12">
          <span class="d-flex font-weight-black justify-space-around">
            {{ allInspections.length }} de {{ inspectionsTarget }} esperadas
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import { Debounce } from '@/utils/decorators'
  import Vue from 'vue'
  import { Prop, Watch } from 'vue-property-decorator'
  import Component from 'vue-class-component'
  import dayjs from 'dayjs'
  import { deepCopy } from '@/utils/general'

@Component({})
  export default class Canceled extends Vue {
  @Prop() inspections
  @Prop() inspectionsSettings
  @Prop({ default: undefined, type: Number }) idExecutive
  @Prop({ default: undefined }) executives
  inspectionsAttribute = []
  allInspections = []
  dayjs = dayjs

  getData () {
    if (!this.inspections?.records) return
    let inspections = deepCopy(this.inspections?.records)
    if (this.idExecutive) {
      inspections = inspections.filter(inspection => inspection.executiveId === this.idExecutive)
    }
    if (!inspections) return

    this.allInspections = inspections
      .sort((prev, next) => {
        const isSame = dayjs(next.scheduledDate).isSame(dayjs(prev.scheduledDate), 'hour')
        if (isSame) return 0
        return dayjs(next.scheduledDate).isAfter(dayjs(prev.scheduledDate)) ? 1 : -1
      })
    this.inspectionsAttribute = this.allInspections
      .filter(inspection => {
        return inspection.closingReason?.description === 'Cancelada'
      })
  }

  get inspectionsTarget () {
    const executivesNumber = this.executives?.executivesForReports?.length
    return Math.round((this.inspectionsSettings?.settingsProcess?.[0]?.config?.projectedUtility?.cancelled / (executivesNumber || 1)) * (this.idExecutive ? 1.2 : 1)) || 0
  }

  @Watch('inspections', { immediate: true, deep: true })
  @Watch('inspectionsSettings', { immediate: true, deep: true })
  @Debounce(500)
  updateData () {
    this.getData()
  }
  }
</script>

<style scoped lang="scss">
span {
  background-color: #F5F5F5;
  border-radius: 2rem;
  height: min-content;

  &.plate {
    width: 5rem;
  }
}

.list {
  max-height: 400px;
  overflow-y: auto;
}

span:has(.car-model) {
  max-width: calc(100% - 120px);
  background: none;
}

@media (max-width: 480px) {
  span:has(.car-model) {
    max-width: 14rem;
  }
}

@media (max-width: 456px) {
  span:has(.car-model) {
    max-width: 12rem;
  }
}

@media (max-width: 424px) {
  span:has(.car-model) {
    max-width: 8rem;
  }
}
</style>
