
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { Debounce } from '@/utils/decorators'

@Component({})
  export default class DealClosingReasonTreeMap extends BaseChart {
  colors = ['#1E90FF', '#FF6347', '#FFD700', '#32CD32', '#FF69B4', '#8A2BE2', '#00CED1', '#FF4500', '#DA70D6', '#40E0D0', '#FF8C00', '#ADFF2F']

  // Methods
  mounted () {
    this.chartOptions = {
      chart: {
        type: 'treemap',
      },
      colors: this.colors,
      tooltip: {
        y: {
          formatter: (val: number) => {
            return val + ' leads'
          },
        },
      },
      plotOptions: {
        treemap: {
          distributed: true, // Asigna un color distinto a cada sección
          enableShades: true, // Habilita sombras para mejor visibilidad
          shadeIntensity: 0.5, // Intensidad de las sombras
        },
      },
    }

    this.getClosing()

    this.display = true
  }

  @Prop() closingReason: Record<string, any>

  getClosing () {
    const closing = this.closingReason
    if (!this.closingReason) return

    const transformedData = this.transformClosingReasonData(closing.aggregates)

    this.series = []
    if (transformedData) {
      this.series.push({
        data: transformedData,
      })
    }
    if (this.series?.[0]?.data) {
      this.series[0].data = this.series[0].data.sort((prev, next) => {
        return next.y - prev.y
      })
    }
  }

  transformClosingReasonData (aggregates: any) {
    if (aggregates) {
      return aggregates
        .filter((item: any) => item.leads.aggregate.count > 0) // Filtra los elementos con valores mayores que 0
        .map((item: any) => ({
          x: item.type.description,
          y: item.leads.aggregate.count,
        }))
    }
  }

  // Getters

  get dealsTotal () {
    let count = 0
    this.series?.[0]?.data.forEach(item => count += item.y)
    return count
  }

  // Watchers
  @Watch('closingReason', { immediate: true, deep: true })
  @Debounce(500)
  onDatesChange () {
    this.display = false
    this.getClosing()
    this.display = true
  }
  }
