
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { DetailChart } from './detailChart'
  import CarDetail from './Detail/CarDetail.vue'

@Component({
  components: {
    CarDetail,
  },
})
  export default class DetailSalesByCar extends DetailChart {
  @Prop() sales: Record<string, any>
  @Prop() salesTarget: Record<string, any>
  @Prop({ default: undefined, type: Number }) idExecutive
  @Prop({ default: undefined, type: Number }) executives

  amount = 0
  target = 10
  cars = []

  // Methods
  mounted () {
    this.getData()
  }

  getData () {
    if (!this.sales?.records || !this.target) return
    const cars = this.idExecutive ? this.sales?.records.filter(sale => sale.executive === this.idExecutive) : this.sales?.sales
    if (cars) {
      this.cars = [...cars].sort((prev, next) => {
        return -dayjs(prev.soldDate).diff(dayjs(next.soldDate))
      })
    }

    this.amount = this.cars.length
    const salesTarget = Math.round((this.salesTarget?.settings_process?.[0]?.config?.projectedUtility?.quantity / (this.executives || 1)) * (this.executives ? 1.2 : 1))
    if (salesTarget) {
      this.target = salesTarget
    }
  }

  @Watch('sales', { immediate: true, deep: true })
  @Watch('salesTarget', { immediate: true, deep: true })
  @Watch('executives', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
